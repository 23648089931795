import axios from "axios";
import { gnov } from "flowy-3-core";
import config from "../config";

/**
 * Search approved curp in the server
 *
 * @param {string} curp curp we're searching for
 * @returns
 */
async function searchCurp(curp: string) {
  const fid = "q15";
  const formId = "645bd0e9be3d3b001d6bf817";
  const filter = `{{${fid}}} == "${curp}" AND ({{status}} == "s2" OR {{status}} == "s4" OR {{status}} == "s6" OR {{status}} == "s7")`;
  const searchUrl = `${config.apiUrl}/main_api/v1/public/search/root_gizmo/data/${formId}`;

  try {
    const response = await axios({
      method: "post",
      url: searchUrl,
      data: {
        filter,
      },
    });

    if (response.status === 200) {
      const { filledForms } = response.data;

      if (filledForms.length > 0) {
        const rootGizmoDataFid = gnov(filledForms[0], `filledFormId`);
        const downloadUrl = `${config.apiUrl}/public/forms/${formId}/filled_forms/pdf/${rootGizmoDataFid}?pdfFolio=aprobado_artesana`;
        const annexUrl = `${config.apiUrl}/public/forms/${formId}/filled_forms/pdf/${rootGizmoDataFid}?pdfFolio=artesana_anexo_dos`;

        return { downloadUrl, annexUrl };
      }
    }
  } catch (e) {
    console.log("eeeeeeeeeerror: ", e);
  }
}

export default searchCurp;
