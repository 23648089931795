import {
  CloseOutlined,
  DownloadOutlined,
  QrcodeOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Col,
  Divider,
  Input,
  message,
  Table,
  TablePaginationConfig,
  Typography,
} from "antd";
import { useState, useEffect } from "react";
import { QrReader } from "react-qr-reader";
import { quintanaRooHeader } from "../assets/images";
import searchCurp from "./searchCurp";
import downloadPdf from "./downloadPdf";
import * as S from "./ApprovedSearch.styles";
import { ColumnsType } from "antd/es/table";
import getIndex, { IIndex, IPagination } from "./getIndex";

const { Search } = Input;
const { Text, Title, Paragraph } = Typography;
const curpRegex: RegExp = /^[A-Z]{4}\d{6}[H,M][A-Z]{5}[A-Z\d]{2}$/;

interface DataType {
  key: React.Key;
  folio: string;
  municipality: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Folio",
    dataIndex: "folio",
    key: "folio",
    align: "center",
  },
  {
    title: "Municipio",
    dataIndex: "municipality",
    key: "municipality",
    align: "center",
  },
];

const pageSize = 145;

const ApprovedSearch = () => {
  const [activeQrReader, setActiveQrReader] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [approved, setApproved] = useState<boolean | undefined>();
  const [loadingPage, setLoadingPage] = useState<boolean>(false);
  const [loadingValidation, setLoadingValidation] = useState<boolean>(false);
  const [loadingDownload, setLoadingDownload] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [downloadUrl, setDownloadUrl] = useState("");
  const [annexUrl, setAnnexUrl] = useState("");
  // Index data
  // This data are the rows
  const [data, setData] = useState<any[]>([]);
  const [pagination, setPagination] = useState<IPagination | undefined>();

  useEffect(() => {
    getIndex({ page: 1, pageSize }).then((index: IIndex | undefined) => {
      if (index) {
        setData(index.rows);
        setPagination(index.pagination);
      }
    });
  }, []);

  const onSearch = async (value: string) => {
    setLoadingValidation(true);
    if (!value) {
      showErrorMessage("Ingrese el CURP!");
    } else if (isCurpValid(value)) {
      // const downloadUrl = await searchCurp(value);
      const urls = await searchCurp(value);
      if (urls) {
        const { downloadUrl, annexUrl } = urls;

        setDownloadUrl(downloadUrl);
        setAnnexUrl(annexUrl);
        setApproved(true);
      } else {
        setApproved(false);
      }
      setActiveQrReader(false);
    } else {
      showErrorMessage("No es un CURP válido!");
    }
    setLoadingValidation(false);
  };

  const onChangeSearch = (e: any) => {
    setSearchValue(e.target.value);
  };

  const handleQrReader = () => {
    setActiveQrReader(true);
    setSearchValue("");
    setApproved(undefined);
  };

  const handleCancelQrReader = () => {
    setActiveQrReader(false);
  };

  const handleResultQrReader = (result: any) => {
    if (!!result) {
      let searchValue = "";
      if (result?.text.includes("|")) {
        searchValue = result?.text.split("|")[0];
        if (isCurpValid(searchValue)) {
          setSearchValue(searchValue);
          onSearch(searchValue);
          setActiveQrReader(false);
        } else {
          showErrorMessage("No es un CURP válido!");
        }
      } else {
        showErrorMessage("No es un código QR válido!");
      }
    }
  };

  const isCurpValid = (curp: string) => curpRegex.test(curp);

  const showErrorMessage = (content: string) => {
    messageApi.destroy();
    messageApi.open({ type: "error", content });
  };

  const downloadPDF = async (url: string, filename: string) => {
    setLoadingDownload(true);
    // if (downloadUrl) await downloadPdf(downloadUrl);
    if (downloadUrl) await downloadPdf(url, filename);
    setLoadingDownload(false);
  };

  const handlePageChange = async (pageData: TablePaginationConfig) => {
    setLoadingPage(true);

    if (pageData.current) {
      const index = await getIndex({
        page: pageData.current,
        pageSize: pageData.pageSize,
      });

      if (index) {
        setData(index.rows);
        setPagination(index.pagination);
      }
    }

    setLoadingPage(false);
  };

  return (
    <S.Container>
      {contextHolder}
      <S.HeaderImage src={quintanaRooHeader} />
      <S.CurpInputContainer justify="center">
        <Search
          placeholder="Ingrese o escaneé el código QR del CURP"
          onSearch={onSearch}
          onChange={onChangeSearch}
          enterButton
          value={searchValue}
          loading={loadingValidation}
          addonAfter={
            <>
              <Divider type="vertical" />
              <Button
                icon={<QrcodeOutlined />}
                type="primary"
                disabled={loadingValidation}
                onClick={handleQrReader}
              />
            </>
          }
        />
        {activeQrReader && (
          <div>
            <QrReader
              videoContainerStyle={{ paddingTop: 10 }}
              videoStyle={{
                maxWidth: 500,
                maxHeight: 500,
                position: "relative",
                display: "initial",
              }}
              onResult={handleResultQrReader}
              constraints={{
                facingMode: "environment",
              }}
            />
            <Button danger onClick={handleCancelQrReader}>
              Cerrar
            </Button>
          </div>
        )}
      </S.CurpInputContainer>
      <S.ResultContainer gutter={10}>
        {approved !== undefined ? (
          <>
            {approved ? (
              <S.CenterDiv>
                <Alert message="Validado" type="success" />
                <S.ParagraphContainer>
                  <Paragraph>
                    El Gobierno del Estado de Quintana Roo, a través de la
                    Secretaría de Bienestar, tiene el gusto de informarle que su
                    solicitud de incorporación al PROGRAMA ARTESANAS DEL
                    BIENESTAR ha sido validada, por lo que es usted designada
                    como persona Titular Beneficiaria, gracias a que cumplió con
                    los requisitos establecidos en la convocatoria y en las
                    Reglas de Operación, es por ello, que la invitamos a estar
                    pendiente por medio de las redes oficiales y el micrositio
                    <a
                      href="https://qroo.gob.mx/sedeso/artesanasdelbienestar/"
                      target="_blank"
                    >
                      {" "}
                      artesanas del bienestsar{" "}
                    </a>{" "}
                    de las fechas de entrega de los Apoyos.
                  </Paragraph>
                  <Paragraph>
                    Recuerde asistir a la entrega con la siguiente
                    documentación:
                  </Paragraph>
                  <ol>
                    <li>
                      <Paragraph>
                        Copia de identificación oficial vigente con fotografía.
                      </Paragraph>
                    </li>
                    <li>
                      <Paragraph>
                        Impreso del folio de aceptación, misma que podrá obtener
                        a través de la plataforma.
                      </Paragraph>
                    </li>
                  </ol>
                  <Paragraph>
                    Muchas gracias por formar parte de los Programas del
                    Bienestar del Gobierno del Estado de Quintana Roo,
                    implementados por la Secretaría de Bienestar. Recuerde que
                    ahora todos los apoyos son gratuitos y directos. ¡Trabajamos
                    para que esté bien!
                  </Paragraph>
                </S.ParagraphContainer>
                <Button
                  type="primary"
                  icon={<DownloadOutlined />}
                  style={{ marginTop: 10 }}
                  onClick={async () => await downloadPDF(downloadUrl, "recibo_beneficiario.pdf")}
                  loading={loadingDownload}
                >
                  Descargar PDF
                </Button>
                <Divider type="vertical" />
                <Button
                  type="primary"
                  icon={<DownloadOutlined />}
                  style={{ marginTop: 10 }}
                  onClick={async () => await downloadPDF(annexUrl, "anexo_2.pdf")}
                  loading={loadingDownload}
                >
                  Descargar Anexo 2
                </Button> 
                <Divider type="vertical" />
                <Button
                  type="default"
                  icon={<CloseOutlined />}
                  style={{ marginTop: 10 }}
                  onClick={() => setApproved(undefined)}
                >
                  Cerrar
                </Button>
              </S.CenterDiv>
            ) : (
              <S.CenterDiv>
                <Alert message="No aplica" type="error" />
                <S.ParagraphContainer>
                  <Paragraph>
                    El Gobierno del Estado de Quintana Roo, a través de la
                    Secretaría de Bienestar, agradece el interés que ha
                    manifestado para participar en el{" "}
                    <Text strong>PROGRAMA ARTESANAS DEL BIENESTAR</Text>. Le
                    informamos de la manera más atenta y respetuosa, que su
                    solicitud no ha sido aplicada, pero implementaremos nuevos
                    programas para que usted y su familia puedan tener un mayor
                    bienestar, por lo que las y los invitamos a que se mantengan
                    atentos a las próximas convocatorias. Seguiremos trabajando
                    en más programas para contribuir en mejorar la calidad de
                    vida de más familias quintanarroenses.
                  </Paragraph>
                </S.ParagraphContainer>
                <Button
                  type="primary"
                  icon={<CloseOutlined />}
                  style={{ marginTop: 10 }}
                  onClick={() => setApproved(undefined)}
                >
                  Cerrar
                </Button>
              </S.CenterDiv>
            )}
          </>
        ) : (
          <Table
            dataSource={data}
            columns={columns}
            bordered
            style={{ width: "100%" }}
            loading={loadingPage}
            onChange={handlePageChange}
            scroll={{ y: 500 }}
            pagination={{
              pageSize: pagination?.pageSize,
              total: pagination?.total,
              current: pagination?.page,
              showSizeChanger: false,
              showQuickJumper: true,
            }}
          />
        )}
      </S.ResultContainer>
      <Divider />
      <S.FooterContainer gutter={10}>
        <Col span={24}>
          <Typography>
            <Title level={5}>Informes:</Title>
            <Paragraph>
              Cualquier información o consulta adicional, será atendida en
              horario de las 9:00 a 16:00 horas en los teléfonos (983) 285 0476
              ext. 207 o en las oficinas de la Secretaría de Bienestar del
              Estado de Quintana Roo, ubicadas en:
            </Paragraph>
            <ol>
              <li>
                <Paragraph>
                  Av. Lázaro Cárdenas no. 169 entre 5 de mayo y 16 de
                  septiembre, Col. Plutarco Elías Calles, ciudad de Chetumal,
                  Quintana Roo C.P. 77090.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  Av. Palenque S/N, SM. 35, entre Av. Xcaret y Av. Coba, Plaza
                  Hollywood, Primer piso, Ciudad Cancún, Quintana Roo, C.P. 7
                  7505.
                </Paragraph>
              </li>
            </ol>
            <Paragraph>
              De igual manera podrán enviar sus aclaraciones o dudas al correo
              electrónico
              <a
                href="mailto:artesanasdelbienestar.qroo@gmail.com"
                target="_blank"
              >
                {" "}
                artesanasdelbienestar.qroo@gmail.com
              </a>
            </Paragraph>
          </Typography>
        </Col>
      </S.FooterContainer>
    </S.Container>
  );
};

export default ApprovedSearch;
