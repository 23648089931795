import axios from "axios";

/**
 * Download a pdf file from a given url
 */
async function downloadPdf(url: string, filename: string) {
  try {
    const response = await axios({
      method: "get",
      url,
      responseType: "blob",
    });

    if (response.status === 200) {
      const blob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    }
  } catch (e) {}
}

export default downloadPdf;
